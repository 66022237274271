import { getCurrentDate } from "@maven-helper/functions/Misc.function";
import { useState } from "react";
import { GrCheckbox, GrDialog, GrStack, GrTypography, palette } from "@jnpmedi/grebob";
import { MarkdownContent } from "./MarkdownContent";
import Style from "./Popup.styled";
import { readableMavenProductServiceWithClinicalCloud } from "@maven-rest/admin/Notice.conv";
import { AdminReleaseWithContent } from "@maven-rest/admin/Release.dto";
import { setCookie } from "@maven-rest/states";
import { COMMON_LANG } from "@maven-msg/lang";
import { MvnMessage } from "@maven-msg/lib/Message";
import dayjs from "dayjs";

interface NoticePopupProps {
  open: boolean;
  release: AdminReleaseWithContent;
  onClose: () => void;
}

export const BUILDER_RELEASE_POPUP_KEY = "builder:release:popup:key";

export const ReleasePopup = ({ open, release, onClose }: NoticePopupProps) => {
  const [checked, setChecked] = useState<boolean>(false);

  return (
    <GrDialog
      open={open}
      title="Version & Release"
      onClose={onClose}
      confirmButton={{ color: "grey", children: MvnMessage({ ...COMMON_LANG.CLOSE, ko: "닫기" }) }}
      footer={
        <GrStack>
          <GrCheckbox label={{ text: MvnMessage(COMMON_LANG.DO_NOT_SHOW_TODAY) }} checked={checked} onChange={() => setChecked(!checked)} />
        </GrStack>
      }
      onConfirm={() => {
        if (checked) {
          const currentDate = getCurrentDate();
          setCookie(BUILDER_RELEASE_POPUP_KEY, release.version, dayjs(currentDate).add(1, "day").toDate());
        }
        onClose();
      }}
    >
      <GrStack flexDirection="column">
        <Style.Container>
          <GrStack backgroundColor={palette.bg020} padding="1.25rem" borderRadius="0.375rem" flexDirection="column" gap="0.625rem">
            <GrStack gap="0.375rem" flexDirection="column">
              <GrTypography type="Content.Title03" color="grey090">
                {readableMavenProductServiceWithClinicalCloud(release.service)}
              </GrTypography>
              <GrStack>
                <GrStack gap="0.5rem" width="50%">
                  <GrTypography color="grey060" type="Content.Caption02">
                    Current Version
                  </GrTypography>
                  <GrTypography type="Content.Caption02" color="grey090">
                    {release.version}
                  </GrTypography>
                </GrStack>
                <GrStack gap="0.5rem" width="50%">
                  <GrTypography color="grey060" type="Content.Caption02">
                    Updated
                  </GrTypography>
                  <GrTypography type="Content.Caption02" color="grey090">
                    {dayjs(release.updatedAt).format("MM/DD/YYYY")}
                  </GrTypography>
                </GrStack>
              </GrStack>
            </GrStack>
          </GrStack>
          <Style.Content>
            <MarkdownContent text={MvnMessage({ en: release.content.en || "", ko: release.content.ko || "" })} />
          </Style.Content>
        </Style.Container>
      </GrStack>
    </GrDialog>
  );
};
