import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";
export const CRF_ITEM_LANG: {
  CRF_ITEM_CALENDAR_DATE_INVALID: MvnCodeFE;
  CRF_ITEM_CALENDAR_DATE_OUT_OF_RANGE: MvnCodeFE;
  CRF_ITEM_API_CALL_COMMON_FAIL: MvnCodeFE;
  CRF_ITEM_API_CALL_RESPONSE_EMPTY: MvnCodeFE;
} = {
  CRF_ITEM_CALENDAR_DATE_INVALID: {
    ko: "입력한 날짜가 올바르지 않습니다.",
    en: "The date is invalid.",
  },
  CRF_ITEM_CALENDAR_DATE_OUT_OF_RANGE: {
    ko: "유효한 범위를 벗어났습니다.",
    en: "The date entered is outside the valid range.",
  },
  CRF_ITEM_API_CALL_COMMON_FAIL: {
    ko: "API 연동에 실패했습니다. 다시 시도해 주세요",
    en: "API connection failed, please try again",
  },
  CRF_ITEM_API_CALL_RESPONSE_EMPTY: {
    ko: "일치하는 정보가 없습니다.",
    en: "No matching information found.",
  },
};

MvnCodeInitiate(CRF_ITEM_LANG);
