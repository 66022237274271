import styled from "@emotion/styled";
import { palette } from "@jnpmedi/grebob";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

const StyledMarkdown = styled.div`
  white-space: break-spaces;
  word-break: break-all;
  font-size: 0.75rem;
  letter-spacing: -0.2px;
  line-height: 120%;
  margin: 0;
  h1 {
    font-size: 1.125rem;
    margin: 0.5rem 0;
  }
  h2 {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
  h3 {
    font-size: 0.875rem;
    margin: 0.5rem 0;
  }
  ul {
    line-height: 50%;
  }
  ol {
    line-height: 50%;
  }
  p {
    code {
      padding: 0 0.25rem;
      border-radius: 0.25rem;
      background-color: ${palette.grey020};
    }
  }
  pre {
    width: 100%;
    code: {
      display: block;
      width: 100%;
      padding: 0.75rem;
      border-radius: 0.25rem;
      background-color: ${palette.grey020};
    }
  }
  table {
    border-collapse: collapse;
  }
  td {
    border: 1px solid ${palette.grey040};
    padding: 0.5rem;
  }
  th {
    border: 1px solid ${palette.grey040};
    padding: 0.5rem;
  }
  blockquote {
    margin: 1rem 0;
    padding: 0 0.75rem;
    border-radius: 0 0.25rem 0.25rem 0;
    border-left: 4px solid ${palette["maven-blue-dark"]};
    background-color: ${palette.grey020};
    p {
      margin: 0;
    }
  }
  hr {
    height: 1px;
  }
`;

interface MarkdownProps {
  text: string;
}

// TODO: grebob으로 이동
export function MarkdownContent(props: MarkdownProps) {
  return (
    <StyledMarkdown>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
        {props.text}
      </ReactMarkdown>
    </StyledMarkdown>
  );
}
