import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_BUILDER_ROLE_LANG: {
  BUILDER_STUDY_BUILDER_ROLE_MODIFY_SUCC: MvnCodeFE;
  BUILDER_STUDY_BUILDER_ROLE_MODIFY_FAIL: MvnCodeFE;
} = {
  BUILDER_STUDY_BUILDER_ROLE_MODIFY_SUCC: {
    ko: "Builder Role을 성공적으로 수정하였습니다.",
    en: "You have modified Builder Role successfully.",
  },
  BUILDER_STUDY_BUILDER_ROLE_MODIFY_FAIL: {
    ko: "Builder Role 수정에 실패했습니다. ({message})",
    en: "Failed to modify Builder Role. ({message})",
  },
};
