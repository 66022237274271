import { getCurrentDate } from "@maven-helper/functions/Misc.function";
import { useState } from "react";
import { GrCheckbox, GrChip, GrDialog, GrStack, GrTypography, palette } from "@jnpmedi/grebob";
import { MarkdownContent } from "./MarkdownContent";
import Style from "./Popup.styled";
import { readableMavenProductServiceWithClinicalCloud } from "@maven-rest/admin/Notice.conv";
import { AdminNoticeType, AdminNoticeWithContent } from "@maven-rest/admin/Notice.dto";
import { setCookie } from "@maven-rest/states";
import { COMMON_LANG } from "@maven-msg/lang";
import { MvnCodeFE } from "@maven-msg/lang/const";
import { MvnMessage } from "@maven-msg/lib/Message";
import dayjs from "dayjs";

interface NoticePopupProps {
  open: boolean;
  notice: AdminNoticeWithContent;
  onClose: () => void;
}

export const BUILDER_NOTICE_POPUP_KEY = "builder:notice:popup:key";

export const NoticePopup = ({ open, notice, onClose }: NoticePopupProps) => {
  const [checked, setChecked] = useState<boolean>(false);

  return (
    <GrDialog
      open={open}
      title={MvnMessage({ en: notice.title.en || "", ko: notice.title.ko || "" })}
      onClose={onClose}
      confirmButton={{ color: "grey", children: MvnMessage({ ...COMMON_LANG.CLOSE, ko: "닫기" }) }}
      footer={
        <GrStack>
          <GrCheckbox
            label={{ text: MvnMessage({ ...COMMON_LANG.DO_NOT_SHOW_TODAY }) }}
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
        </GrStack>
      }
      onConfirm={() => {
        if (checked) {
          const currentDate = getCurrentDate();
          setCookie(`${BUILDER_NOTICE_POPUP_KEY}:${notice.id}`, notice.id.toString(), dayjs(currentDate).add(1, "day").toDate());
        }
        onClose();
      }}
    >
      <GrStack flexDirection="column">
        <Style.Container>
          <GrStack backgroundColor={palette.bg020} padding="1.25rem" flexDirection="column" gap="0.625rem" borderRadius="0.375rem">
            <GrStack alignItems="center" gap="0.375rem">
              {notice.type === AdminNoticeType.SystemOperation && (
                <GrChip color="orange" variant="outlined-soft" shape="circle" label="System Check" size="medium" />
              )}
              {notice.type === AdminNoticeType.ResolvedIncident && (
                <GrChip color="green" variant="outlined-soft" shape="circle" label="Resolve Incident" size="medium" />
              )}
              <GrTypography type="Content.Title03" color="grey090">
                {readableMavenProductServiceWithClinicalCloud(notice.service)}
              </GrTypography>
            </GrStack>
            <GrTypography type="Content.Subtitle04" color="grey090">
              {dayjs(notice.startAt).format("YYYY/MM/DD hh:mm:ss Z")}
              {" - "}
              {dayjs(notice.dueAt).format("YYYY/MM/DD hh:mm:ss Z")}
            </GrTypography>
          </GrStack>
          <Style.Content>
            <MarkdownContent text={MvnMessage(notice.content as MvnCodeFE)} />
          </Style.Content>
        </Style.Container>
      </GrStack>
    </GrDialog>
  );
};
