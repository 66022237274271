import { FxApiRequestData, FxRequestData } from "./../common/flax.types";
import { GetMyInfoRequest, GetMyInfoResponse } from "./User.dto";
import { BuilderUserUrl } from "./User.url";
import { restReq } from "../common/cyan.types";

export class BuilderUserFlax {
  public static getMyInfo(data: FxRequestData<GetMyInfoRequest>): FxApiRequestData<GetMyInfoResponse> {
    return {
      ...restReq(process.env.REACT_APP_BUILDER_BE_URL, BuilderUserUrl.getMyInfo, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }
}
