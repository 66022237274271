import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_TEMPLATE_LANG: {
  BUILDER_STUDY_TEMPLATE_ADD_REQUEST_SUCC: MvnCodeFE;
  BUILDER_STUDY_TEMPLATE_ADD_REQUEST_FAIL: MvnCodeFE;
  BUILDER_STUDY_TEMPLATE_EDIT_SUCC: MvnCodeFE;
  BUILDER_STUDY_TEMPLATE_EDIT_FAIL: MvnCodeFE;
  BUILDER_STUDY_TEMPLATE_DELETE_SUCC: MvnCodeFE;
  BUILDER_STUDY_TEMPLATE_DELETE_FAIL: MvnCodeFE;
  BUILDER_STUDY_TEMPLATE_DELETE_CONFIRM_DIALOG_TITLE: MvnCodeFE;
  BUILDER_STUDY_TEMPLATE_DELETE_CONFIRM_DIALOG_DESC: MvnCodeFE;
  BUILDER_STUDY_TEMPLATE_ARCHIVE_SUCC: MvnCodeFE;
  BUILDER_STUDY_TEMPLATE_ARCHIVE_FAIL: MvnCodeFE;
  BUILDER_STUDY_TEMPLATE_ARCHIVE_CONFIRM_DIALOG_TITLE: MvnCodeFE;
  BUILDER_STUDY_TEMPLATE_ARCHIVE_CONFIRM_DIALOG_DESC: MvnCodeFE;
  BUILDER_STUDY_TEMPLATE_EMPTY: MvnCodeFE;
} = {
  BUILDER_STUDY_TEMPLATE_ADD_REQUEST_SUCC: {
    ko: "Template 등록 요청되었습니다.",
    en: "Adding to template has been requested.",
  },
  BUILDER_STUDY_TEMPLATE_ADD_REQUEST_FAIL: {
    ko: "Template 등록 요청이 실패하였습니다. ({message})",
    en: "Failed to request to adding to template. ({message})",
  },
  BUILDER_STUDY_TEMPLATE_EDIT_SUCC: {
    ko: "Study Template을 수정하였습니다.",
    en: "You have edited study template successfully.",
  },
  BUILDER_STUDY_TEMPLATE_EDIT_FAIL: {
    ko: "Study Template을 수정에 실패했습니다. ({message})",
    en: "Failed to edit study template. ({message})",
  },
  BUILDER_STUDY_TEMPLATE_DELETE_SUCC: {
    ko: "Study Template을 삭제했습니다.",
    en: "You have deleted study template successfully.",
  },
  BUILDER_STUDY_TEMPLATE_DELETE_FAIL: {
    ko: "Study Template 삭제에 실패했습니다. ({message})",
    en: "Failed to delete study template. ({message})",
  },
  BUILDER_STUDY_TEMPLATE_DELETE_CONFIRM_DIALOG_TITLE: {
    ko: "Delete Template",
    en: "Delete Template",
  },
  BUILDER_STUDY_TEMPLATE_DELETE_CONFIRM_DIALOG_DESC: {
    ko: "해당 템플릿을 삭제하기 위해 이메일과 비밀번호를 입력하세요.",
    en: "Please enter your e-mail and password to delete this template.",
  },
  BUILDER_STUDY_TEMPLATE_ARCHIVE_SUCC: {
    ko: "Study Template을 아카이빙 했습니다.",
    en: "You have archived study template successfully.",
  },
  BUILDER_STUDY_TEMPLATE_ARCHIVE_FAIL: {
    ko: "Study Template 아카이빙에 실패했습니다. ({message})",
    en: "Failed to archive study template. ({message})",
  },
  BUILDER_STUDY_TEMPLATE_ARCHIVE_CONFIRM_DIALOG_TITLE: {
    ko: "Archive Template",
    en: "Archive Template",
  },
  BUILDER_STUDY_TEMPLATE_ARCHIVE_CONFIRM_DIALOG_DESC: {
    ko: "해당 템플릿을 아카이빙하기 위해 이메일과 비밀번호를 입력하세요.",
    en: "Please enter your e-mail and password to archive this template.",
  },
  BUILDER_STUDY_TEMPLATE_EMPTY: {
    ko: "생성된 항목이 없습니다.",
    en: "No template yet.",
  },
};
