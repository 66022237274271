import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_LAB_NORMAL_RANGE_LANG: {
  LAB_NORMAL_RANGE_SUCCESS: MvnCodeFE;
  LAB_NORMAL_RANGE_FAIL: MvnCodeFE;
  LAB_NORMAL_RANGE_TOOLTIP_FOR_REMOVE_ROW: MvnCodeFE;
  LAB_NORMAL_RANGE_TOOLTIP_FOR_REMOVE_INPUT: MvnCodeFE;
  LAB_NORMAL_RANGE_FOR_REMOVE_ROW_STRIKEOUT: MvnCodeFE;
  LAB_NORMAL_RANGE_TOOLTIP_FOR_RESTORE_ROW: MvnCodeFE;
} = {
  LAB_NORMAL_RANGE_SUCCESS: {
    ko: "페이지 정보를 저장했습니다.",
    en: "Page has been saved successfully.",
  },
  LAB_NORMAL_RANGE_FAIL: {
    ko: "페이지 저장에 실패하였습니다. ({message})",
    en: "Failed to save the page. ({message})",
  },
  LAB_NORMAL_RANGE_TOOLTIP_FOR_REMOVE_ROW: {
    ko: "Row 삭제",
    en: "Remove Row",
  },
  LAB_NORMAL_RANGE_TOOLTIP_FOR_REMOVE_INPUT: {
    ko: "입력 값 삭제",
    en: "Remove Input",
  },
  LAB_NORMAL_RANGE_FOR_REMOVE_ROW_STRIKEOUT: {
    ko: "Row 삭제(취소선)",
    en: "Remove Row(strikeout)",
  },
  LAB_NORMAL_RANGE_TOOLTIP_FOR_RESTORE_ROW: {
    ko: "Row 복원",
    en: "Restore Row",
  },
};

MvnCodeInitiate(STUDY_LAB_NORMAL_RANGE_LANG);
