import { PaginateBuilderMyOrganizationsRequest, PaginateBuilderMyOrganizationsResponse } from "./Organization.dto";
import { BuilderOrganizationUrl } from "./Organization.url";
import { restReq } from "../common/cyan.types";
import { FxApiRequestData, FxRequestData } from "../common/flax.types";

export class BuilderOrganizationFlax {
  public static paginateBuilderMyOrganizations(
    data: FxRequestData<PaginateBuilderMyOrganizationsRequest>
  ): FxApiRequestData<PaginateBuilderMyOrganizationsResponse> {
    return {
      ...restReq(process.env.REACT_APP_BUILDER_BE_URL, BuilderOrganizationUrl.paginateBuilderMyOrganizations, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }
}
