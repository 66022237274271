import styled from "@emotion/styled";

const Container = styled.div`
  overflow-y: auto;
  height: 313px;
  scrollbar-width: thin;
`;

const Content = styled.div`
  margin-top: 1.25rem;
`;

const Style = {
  Content,
  Container,
};

export default Style;
