import { useEffect } from "react";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { Headers, Queries, request } from "@coralblack/flax";
import { ApiErrorResponse, FxApiRequestData } from "@maven-rest/common/flax.types";

type QueryOptions<TQueryFnData, TError, TData> = Omit<UseQueryOptions<TQueryFnData, TError, TData>, "initialData"> & {
  initialData?: () => undefined;
};

type CustomQueryOptions<TQueryFnData, TError, TData> = {
  fnOption?: {
    fxApi: FxApiRequestData<TQueryFnData>;
    query?: Queries;
    headers?: Headers;
  };
  onSuccess?: (data: TData) => void;
  onError?: (error: TError) => void;
} & QueryOptions<TQueryFnData, TError, TData>;

export const useBaseQuery = <TQueryFnData = unknown, TError = ApiErrorResponse, TData = TQueryFnData>({
  fnOption,
  onError,
  onSuccess,
  ...queryOptions
}: CustomQueryOptions<TQueryFnData, TError, TData>) => {
  const query = useQuery<TQueryFnData, TError, TData>({
    queryFn: fnOption?.fxApi
      ? async () => {
          const resp = await request({ ...fnOption.fxApi, query: fnOption.query, headers: fnOption?.headers });

          return resp.data;
        }
      : queryOptions.queryFn,
    ...queryOptions,
  });

  useEffect(() => {
    if (query.isError && !!onError) {
      onError(query.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isError]);

  useEffect(() => {
    if (query.isSuccess && !!onSuccess) {
      onSuccess(query.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isSuccess]);

  return query;
};
