import { useDispatch } from "react-redux";
import { RouteProps } from "react-router-dom";
import { FxGuard } from "@coralblack/flax";
import { Popup } from "./components/popup/Popup";
import { PaginateBuilderMyOrganizationsResponse } from "@maven-rest/builder/Organization.dto";
import { BuilderOrganizationFlax } from "@maven-rest/builder/Organization.flax";
import { BuilderUserFlax } from "@maven-rest/builder/User.flax";
import { sessionSlice } from "@maven-rest/states/index";

interface MyInfoGuardProps extends RouteProps {}

export function MyInfoGuard({ component: Component }: MyInfoGuardProps) {
  const Comp: any = Component;
  const dispatch = useDispatch();

  return (
    <>
      <FxGuard
        api={{ ...BuilderUserFlax.getMyInfo({}) }}
        loading={() => <div className="app-spinner" />}
        render={(userResp) => {
          return (
            <FxGuard
              key="static"
              api={{ ...BuilderOrganizationFlax.paginateBuilderMyOrganizations({}) }}
              render={(orgResp: PaginateBuilderMyOrganizationsResponse | null) => {
                if (userResp) {
                  return (
                    <>
                      <Comp currentUserInfo={userResp.userInfo} currentUserOwnerOrgInfo={orgResp?.items || []} />
                      <Popup />
                    </>
                  );
                }

                return;
              }}
              error={(err) => {
                if (err?.code === "CREDENTIAL-INVALID") {
                  dispatch(sessionSlice.actions.signOut());

                  return <>&nbsp;</>;
                }

                return (
                  <>
                    [{err?.code || "UNKNOWN"}] {err?.message || "An error has occurred."}
                  </>
                );
              }}
            />
          );
        }}
        error={(err) => {
          if (err?.code === "CREDENTIAL-INVALID") {
            dispatch(sessionSlice.actions.signOut());

            return <>&nbsp;</>;
          }

          return (
            <>
              [{err?.code || "UNKNOWN"}] {err?.message || "An error has occurred."}
            </>
          );
        }}
      />
    </>
  );
}
