import { GetPopupsRequest, GetPopupsResponse } from "./Popup.dto";
import { BuilderPopupUrl } from "./Popup.url";
import { restReq } from "../common/cyan.types";
import { FxApiRequestData, FxRequestData } from "../common/flax.types";

export class BuilderPopupFlax {
  public static getPopups(data: FxRequestData<GetPopupsRequest>): FxApiRequestData<GetPopupsResponse> {
    return {
      ...restReq(process.env.REACT_APP_BUILDER_BE_URL, BuilderPopupUrl.getPopups, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }
}
