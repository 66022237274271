import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_TEMPLATE_PAYLOAD_LANG: {
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_DELETE_SUCC: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_DELETE_FAIL: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_DELETE_CONFIRM_DIALOG_TITLE: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_DELETE_CONFIRM_DIALOG_DESC: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_ARCHIVE_SUCC: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_ARCHIVE_FAIL: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_ARCHIVE_CONFIRM_DIALOG_TITLE: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_ARCHIVE_CONFIRM_DIALOG_DESC: MvnCodeFE;
} = {
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_DELETE_SUCC: {
    ko: "Study Template CRF version를 삭제했습니다.",
    en: "You have deleted study template CRF version successfully.",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_DELETE_FAIL: {
    ko: "Study Template CRF version 삭제에 실패했습니다. ({message})",
    en: "Failed to delete study template CRF version. ({message})",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_DELETE_CONFIRM_DIALOG_TITLE: {
    ko: "Delete CRF Version",
    en: "Delete CRF Version",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_DELETE_CONFIRM_DIALOG_DESC: {
    ko: "CRF version을 삭제하기 위해 이메일과 비밀번호를 입력하세요.",
    en: "Please enter your e-mail and password to delete this CRF version.",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_ARCHIVE_SUCC: {
    ko: "Study Template CRF version을 아카이빙 처리했습니다.",
    en: "You have archived study template CRF version successfully.",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_ARCHIVE_FAIL: {
    ko: "Study Template CRF version 아카이빙에 실패했습니다. ({message})",
    en: "Failed to archive study template CRF version. ({message})",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_ARCHIVE_CONFIRM_DIALOG_TITLE: {
    ko: "Archive CRF Version",
    en: "Archive CRF Version",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_ARCHIVE_CONFIRM_DIALOG_DESC: {
    ko: "CRF version을 아카이빙하기 위해 이메일과 비밀번호를 입력하세요.",
    en: "Please enter your e-mail and password to archive this CRF version.",
  },
};
