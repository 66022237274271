import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const DOCS_DOCUMENT_LANG: {
  DOCUMENT_CREATE_DIALOG_HEADER: MvnCodeFE;
  DOCUMENT_UPLOAD_FILE_DIALOG_HEADER: MvnCodeFE;
  DOCUMENT_TITLE_LABEL: MvnCodeFE;
  DOCUMENT_VERSION_LABEL: MvnCodeFE;
  DOCUMENT_AVAILABLE_STORAGE: MvnCodeFE;
  DOCUMENT_AVAILABLE_STORAGE_ARCHIVER: MvnCodeFE;
  DOCUMENT_FILE_LABEL: MvnCodeFE;
  DOCUMENT_FILE_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_FILE_DESCRIPTION: MvnCodeFE;
  DOCUMENT_FILE_TYPE_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_FILE_SUCC: MvnCodeFE;
  DOCUMENT_FILE_FAIL: MvnCodeFE;
  DOCUMENT_FILE_NOT_ALLOWED: MvnCodeFE;
  DOCUMENT_CREATE_SUCC: MvnCodeFE;
  DOCUMENT_CREATE_FAIL: MvnCodeFE;
  DOCUMENT_DOWNLOAD_SUCC: MvnCodeFE;
  DOCUMENT_DOWNLOAD_FAIL: MvnCodeFE;
  DOCUMENT_ESIGN_HEADER: MvnCodeFE;
  DOCUMENT_ESIGN_CREATE_HEADER: MvnCodeFE;
  DOCUMENT_ESIGN_CREATE_DESCRIPTION: MvnCodeFE;
  DOCUMENT_ESIGN_SIGNATURE_HEADER: MvnCodeFE;
  DOCUMENT_ESIGN_SIGNATURE_HEADER_SIGNED: MvnCodeFE;
  DOCUMENT_ESIGN_SIGNATURE_HEADER_CANCELED: MvnCodeFE;
  DOCUMENT_ESIGN_SIGNATURE_HEADER_DECLINED: MvnCodeFE;
  DOCUMENT_REQUEST_ESIGN_BUTTON: MvnCodeFE;
  DOCUMENT_REQUEST_ESIGN_BUTTON_WITH_COUNT: MvnCodeFE;
  DOCUMENT_REQUEST_ESIGN_BUTTON_WITH_COUNT_UNLIMITED: MvnCodeFE;
  DOCUMENT_REQUEST_ESIGN_DESCRIPTION: MvnCodeFE;
  DOCUMENT_CREATE_ESIGN_BUTTON: MvnCodeFE;
  DOCUMENT_CANCEL_ESIGN_BUTTON: MvnCodeFE;
  DOCUMENT_SIGN_ESIGN_BUTTON: MvnCodeFE;
  DOCUMENT_SIGN_ESIGN_ENTER_BUTTON: MvnCodeFE;
  DOCUMENT_SIGN_ESIGN_ENTER_NOTI: MvnCodeFE;
  DOCUMENT_SIGN_ESIGN_REASON_LABEL: MvnCodeFE;
  DOCUMENT_SIGN_ESIGN_REASON_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_SIGN_ESIGN_REASON_APPROVER: MvnCodeFE;
  DOCUMENT_SIGN_ESIGN_REASON_REVIEWER: MvnCodeFE;
  DOCUMENT_SIGN_ESIGN_REASON_OWNER: MvnCodeFE;
  DOCUMENT_SIGN_ESIGN_REASON_OTHER: MvnCodeFE;
  DOCUMENT_SIGN_ESIGN_EMAIL_VERIFICATION_LABEL: MvnCodeFE;
  DOCUMENT_SIGN_ESIGN_EMAIL_VERIFICATION_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_SIGN_DECLINE_BUTTON: MvnCodeFE;
  DOCUMENT_SIGN_CANCEL_BUTTON: MvnCodeFE;
  DOCUMENT_SIGN_DOWNLOAD_BUTTON: MvnCodeFE;
  DOCUMENT_SIGN_DOWNLOAD_BUTTON_GENERATING: MvnCodeFE;
  DOCUMENT_SIGN_DOWNLOAD_REPORT_BUTTON: MvnCodeFE;
  DOCUMENT_SIGN_SIGNATURE_ENTER: MvnCodeFE;
  DOCUMENT_SIGN_STAMP_ENTER: MvnCodeFE;
  DOCUMENT_SIGN_STAMP_DESCRIPTION: MvnCodeFE;
  DOCUMENT_ADD_SIGNER_BUTTON: MvnCodeFE;
  DOCUMENT_NTH_SIGNER_LABEL: MvnCodeFE;
  DOCUMENT_NTH_INVESTIGATOR_LABEL: MvnCodeFE;
  DOCUMENT_SIGN_REQ_FIELDS_FULFILL: MvnCodeFE;
  DOCUMENT_SIGN_SIGNATURE_MISSING: MvnCodeFE;
  DOCUMENT_SIGN_AGREE_AND_PROCEED: MvnCodeFE;
  DOCUMENT_SIGN_DIALOG_HEADER: MvnCodeFE;
  DOCUMENT_SIGN_DECLINE_DIALOG_HEADER: MvnCodeFE;
  DOCUMENT_SIGN_CANCEL_DIALOG_HEADER: MvnCodeFE;
  DOCUMENT_SIGN_DIALOG_AGREEMENT_EFFECT: MvnCodeFE;
  DOCUMENT_SIGN_DIALOG_AGREEMENT_ORIGNAL: MvnCodeFE;
  DOCUMENT_SIGN_DIALOG_AGREEMENT_ETC_PREFIX: MvnCodeFE;
  DOCUMENT_SIGN_DIALOG_AGREEMENT_ETC_LINK: MvnCodeFE;
  DOCUMENT_SIGN_DIALOG_AGREEMENT_ETC_SUFFIX: MvnCodeFE;
  DOCUMENT_SIGN_DIALOG_DECLINE_CONFIRM: MvnCodeFE;
  DOCUMENT_SIGN_DIALOG_DECLINE_WARNING: MvnCodeFE;
  DOCUMENT_SIGN_DIALOG_CANCEL_CONFIRM: MvnCodeFE;
  DOCUMENT_SIGN_DIALOG_CANCEL_WARNING: MvnCodeFE;
  DOCUMENT_SIGN_CANCEL: MvnCodeFE;
  DOCUMENT_SIGN_REQUEST_SUCC: MvnCodeFE;
  DOCUMENT_SIGN_REQUEST_FAIL: MvnCodeFE;
  DOCUMENT_SIGN_CREATE_SUCC: MvnCodeFE;
  DOCUMENT_SIGN_CREATE_FAIL: MvnCodeFE;
  DOCUMENT_SIGN_ESIGN_SUCC: MvnCodeFE;
  DOCUMENT_SIGN_ESIGN_FAIL: MvnCodeFE;
  DOCUMENT_SIGN_DECLINE_SUCC: MvnCodeFE;
  DOCUMENT_SIGN_DECLINE_FAIL: MvnCodeFE;
  DOCUMENT_SIGN_CANCEL_SUCC: MvnCodeFE;
  DOCUMENT_SIGN_CANCEL_FAIL: MvnCodeFE;
  DOCUMENT_SIGN_NAME: MvnCodeFE;
  DOCUMENT_SIGN_NAME_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_SIGN_COMPANY: MvnCodeFE;
  DOCUMENT_SIGN_COMPANY_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_SIGN_TITLE: MvnCodeFE;
  DOCUMENT_SIGN_TITLE_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_SIGN_SIGNATURE: MvnCodeFE;
  DOCUMENT_SIGN_SIGNATURE_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_SIGN_PHONE: MvnCodeFE;
  DOCUMENT_SIGN_PHONE_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_SIGN_PHONE_DESCRIPTION: MvnCodeFE;
  DOCUMENT_SIGN_DATE: MvnCodeFE;
  DOCUMENT_SIGN_LABEL: MvnCodeFE;
  DOCUMENT_SIGN_OPT_GXP_COMPLY: MvnCodeFE;
  DOCUMENT_SIGN_OPT_ADVANCED_MODE: MvnCodeFE;
  DOCUMENT_SIGN_OPT_REQUIRE_EMAIL_VERIFICATION: MvnCodeFE;
  DOCUMENT_SIGN_OPT_REQUIRE_PERSONAL_IDENTIFICATION: MvnCodeFE;
  DOCUMENT_SIGN_OPT_ASSIGN_PROGRAMMATICALLY: MvnCodeFE;
  DOCUMENT_SIGN_OPT_ESIGN_AUTOMATICALLY: MvnCodeFE;
  DOCUMENT_SIGN_OPT_SILENT_MODE: MvnCodeFE;
  DOCUMENT_SIGN_OPT_RESPONSIVE_ITEM_INDEX: MvnCodeFE;
  DOCUMENT_SIGN_OPT_ADD_RESPONSIVE_ITEM_GROUP: MvnCodeFE;
  DOCUMENT_SIGN_OPT_ADD_RESPONSIVE_ITEM: MvnCodeFE;
  DOCUMENT_SIGN_OPT_ADD_LABEL: MvnCodeFE;
  DOCUMENT_SIGN_OPT_RESPONSIVE_ITEM_MISSING_LABEL: MvnCodeFE;
  DOCUMENT_SIGN_OPT_RESPONSIVE_ITEM_MISSING_PLCAEHOLDER: MvnCodeFE;
  DOCUMENT_SIGN_OPT_RESPONSIVE_ITEM_INVALID_LABEL: MvnCodeFE;
  DOCUMENT_SIGN_OPT_RESPONSIVE_ITEM_INVALID_PLCAEHOLDER: MvnCodeFE;
  DOCUMENT_SIGN_OPT_STAMP_API_URL_LABEL: MvnCodeFE;
  DOCUMENT_SIGN_OPT_STAMP_API_URL_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_SIGN_OPT_STAMP_API_TIMING_LABEL: MvnCodeFE;
  DOCUMENT_SIGN_OPT_STAMP_API_TIMING_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_SIGN_OPT_ADD_STAMP_API: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_REASON: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_SIGN_START_PREFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_SIGN_START_SUFFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_DELIVERED_PREFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_DELIVERED_SUFFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_REMIND_PREFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_REMIND_SUFFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_OPENED_PREFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_OPENED_SUFFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_SIGNED_PREFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_SIGNED_SUFFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_DECLINED_PREFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_DECLINED_SUFFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_CANCELED_PREFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_CANCELED_SUFFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_CANCEL_REASON_PREFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_CANCEL_REASON_SUFFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_SIGN_EXPIRED_PREFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_SIGN_EXPIRED_SUFFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_SIGN_END_PREFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_SIGN_END_SUFFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_SIGN_DECLINE_PREFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_SIGN_DECLINE_SUFFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_SIGN_CANCEL_PREFIX: MvnCodeFE;
  DOCUMENT_SIGN_HISTORY_SIGN_CANCEL_SUFFIX: MvnCodeFE;
  DOCUMENT_SIGN_CERTIFICATE_DESC_DIGEST: MvnCodeFE;
  DOCUMENT_SIGN_CERTIFICATE_DESC_DIGEST_HASH: MvnCodeFE;
  DOCUMENT_SIGN_CERTIFICATE_DESC_HASH_CHAIN: MvnCodeFE;
  DOCUMENT_SIGN_DIALOG_CANCEL_REASON: MvnCodeFE;
  DOCUMENT_SIGN_DIALOG_CHANGE_DOC: MvnCodeFE;
  DOCUMENT_SIGN_DIALOG_CHANGE_SIGNEE: MvnCodeFE;
  DOCUMENT_SIGN_DIALOG_DELETE_SIGNEE: MvnCodeFE;
  DOCUMENT_SIGN_DIALOG_OTHER: MvnCodeFE;
  DOCUMENT_SIGN_ECONSENT_BUTTON: MvnCodeFE;
  DOCUMENT_SIGN_FAILED_IDENTIFY_SIGNATURE: MvnCodeFE;
  DOCUMENT_ECONSENT_SIGNATURE_HEADER: MvnCodeFE;
  DOCUMENT_ECONSENT_SIGNATURE_HEADER_SIGNED: MvnCodeFE;
  DOCUMENT_ECONSENT_SIGNATURE_HEADER_CANCELED: MvnCodeFE;
  DOCUMENT_ECONSENT_SIGNATURE_HEADER_DECLINED: MvnCodeFE;
  DOCUMENT_SIGN_ECONSENT_REASON_APPROVER_SIGNER: MvnCodeFE;
  DOCUMENT_SIGN_ECONSENT_REASON_APPROVER_INVESTIGATOR: MvnCodeFE;
  DOCUMENT_ECONSENT_DECLINE_BUTTON: MvnCodeFE;
  DOCUMENT_ECONSENT_CANCEL_BUTTON: MvnCodeFE;
  DOCUMENT_ECONSENT_DOWNLOAD_BUTTON: MvnCodeFE;
  DOCUMENT_ECONSENT_DOWNLOAD_BUTTON_GENERATING: MvnCodeFE;
  DOCUMENT_ECONSENT_DIALOG_HEADER: MvnCodeFE;
  DOCUMENT_ECONSENT_DECLINE_DIALOG_HEADER: MvnCodeFE;
  DOCUMENT_ECONSENT_CANCEL_DIALOG_HEADER: MvnCodeFE;
  DOCUMENT_ECONSENT_DIALOG_AGREEMENT_EFFECT: MvnCodeFE;
  DOCUMENT_ECONSENT_DIALOG_AGREEMENT_ORIGNAL: MvnCodeFE;
  DOCUMENT_ECONSENT_DIALOG_AGREEMENT_ETC_LINK: MvnCodeFE;
  DOCUMENT_ECONSENT_DIALOG_DECLINE_WARNING: MvnCodeFE;
  DOCUMENT_ECONSENT_DIALOG_CANCEL_WARNING: MvnCodeFE;
  DOCUMENT_ECONSENT_LABEL: MvnCodeFE;
  DOCUMENT_ECONSENT_REQ: MvnCodeFE;
  DOCUMENT_ECONSENT_OPT_RESPONSIVE_ITEM_INDEX: MvnCodeFE;
  DOCUMENT_ECONSENT_OPT_ADD_RESPONSIVE_ITEM_GROUP: MvnCodeFE;
  DOCUMENT_ECONSENT_OPT_ADD_OPTION: MvnCodeFE;
  DOCUMENT_ECONSENT_CREATE_ICF_DIALOG_HEADER: MvnCodeFE;
  DOCUMENT_ECONSENT_ADD_SUBJECTS_DIALOG_HEADER: MvnCodeFE;
  DOCUMENT_ECONSENT_ADD_SUBJECT_DIALOG_HEADER: MvnCodeFE;
  DOCUMENT_EXCEL_DOWNLOAD_DESCRIPTION: MvnCodeFE;
  DOCUMENT_ECONSENT_ADD_SUBJECT_LIMIT: MvnCodeFE;
  DOCUMENT_ECONSENT_SUBJECTS_VALIDATE: MvnCodeFE;
  DOCUMENT_ECONSENT_ADD_SUBJECTS_SUCC: MvnCodeFE;
  DOCUMENT_ECONSENT_ADD_SUBJECTS_FAIL: MvnCodeFE;
  DOCUMENT_ECONSENT_ADD_SUBJECT_FAIL: MvnCodeFE;
  DOCUMENT_ECONSENT_CREATE_ICF_SECTION_HEADER: MvnCodeFE;
  DOCUMENT_ECONSENT_CREATE_ICF_SECTION_BUTTON: MvnCodeFE;
  DOCUMENT_ECONSENT_CREATE_ICF_SUCC: MvnCodeFE;
  DOCUMENT_ECONSENT_CREATE_ICF_FAIL: MvnCodeFE;
  DOCUMENT_ECONSENT_SEND_ECONSENT_BUTTON: MvnCodeFE;
  DOCUMENT_ECONSENT_SEND_ECONSENT_DIALOG_HEADER: MvnCodeFE;
  DOCUMENT_ECONSENT_SEND_ECONSENT_DIALOG_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_ECONSENT_SEND_ECONSENT_DIALOG_DESCRIPTION: MvnCodeFE;
  DOCUMENT_ECONSENT_SEND_SUCC: MvnCodeFE;
  DOCUMENT_ECONSENT_SEND_PARTIAL_SUCC_SUFFIX: MvnCodeFE;
  DOCUMENT_ECONSENT_SEND_FAIL: MvnCodeFE;
  DOCUMENT_ECONSENT_AUDIT_VALUE_SIGNER_CREATED: MvnCodeFE;
  DOCUMENT_ECONSENT_AUDIT_VALUE_SIGNER_DELETED: MvnCodeFE;
  DOCUMENT_ECONSENT_AUDIT_VALUE_SIGN_REQUESTED: MvnCodeFE;
  DOCUMENT_SEND_ECONSENT_OPTION_EMAIL: MvnCodeFE;
  DOCUMENT_SEND_ECONSENT_OPTION_MOBILE: MvnCodeFE;
  DOCUMENT_SEND_ECONSENT_ORGANIZATION_TITLE: MvnCodeFE;
  DOCUMENT_SEND_ECONSENT_ORGANIZATION_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_SEND_ECONSENT_EMAIL_IMAGE_TITLE: MvnCodeFE;
  DOCUMENT_SEND_ECONSENT_EMAIL_IMAGE_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_SEND_ECONSENT_SMS_CONTENT_TITLE: MvnCodeFE;
  DOCUMENT_SEND_ECONSENT_SMS_CONTENT_COMMON: MvnCodeFE;
  DOCUMENT_SEND_ECONSENT_SMS_CONTENT_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_SEND_ECONSENT_SMS_CONTENT_DESCRIPTION: MvnCodeFE;
  DOCUMENT_SEND_ECONSENT_TITLE_SUFFIX_KO: MvnCodeFE;
  DOCUMENT_SEND_ECONSENT_TITLE_SUFFIX_EN: MvnCodeFE;
  DOCUMENT_ECONSENT_REQUEST_DOWNLOAD_DOCUMENT_SUCC: MvnCodeFE;
  DOCUMENT_ECONSENT_REQUEST_DOWNLOAD_DOCUMENT_FAIL: MvnCodeFE;
  DOCUMENT_ECONSENT_DONOR: MvnCodeFE;
  DOCUMENT_ECONSENT_AGENT: MvnCodeFE;
  DOCUMENT_ECONSENT_AGENT_ACTIVE: MvnCodeFE;
  DOCUMENT_ECONSENT_AGENT_SIGN_SIGNATURE: MvnCodeFE;
  DOCUMENT_ECONSENT_TEXT_INPUT_FIELD_TITLE: MvnCodeFE;
  DOCUMENT_ECONSENT_TEXT_INPUT_FIELD_LABEL: MvnCodeFE;
  DOCUMENT_ECONSENT_TEXT_INPUT_FIELD_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_ECONSENT_TEXT_FREE_FIELD_LABEL: MvnCodeFE;
  DOCUMENT_ECONSENT_TEXT_DATE_FIELD_LABEL: MvnCodeFE;
  DOCUMENT_ECONSENT_SINGLE_SELECT_FIELD_TITLE: MvnCodeFE;
  DOCUMENT_ECONSENT_DROPDOWN_FIELD_TITLE: MvnCodeFE;
  DOCUMENT_ECONSENT_DROPDOWN_FILED_LABEL: MvnCodeFE;
  DOCUMENT_ECONSENT_DROPDOWN_FIELD_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_ECONSENT_DROPDOWN_MISSING_LABEL: MvnCodeFE;
  DOCUMENT_ECONSENT_DROPDOWN_MISSING_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_ECONSENT_DROPDOWN_OPTION_ITEM_MAX_COUNT: MvnCodeFE;
  DOCUMENT_ECONSENT_ADDRESS_FIELD_TITLE: MvnCodeFE;
  DOCUMENT_ECONSENT_ADD_ITEM_GROUP: MvnCodeFE;
  DOCUMENT_ECONSENT_SELECT_TEXT_FIELD_OPTION_LABEL: MvnCodeFE;
  DOCUMENT_ECONSENT_SELECT_TEXT_FIELD_OPTION_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_ECONSENT_CONSENT: MvnCodeFE;
  DOCUMENT_ECONSENT_CONSENT_ITEM: MvnCodeFE;
  DOCUMENT_ECONSENT_ADD_CONSENT_ITEM: MvnCodeFE;
  DOCUMENT_ECONSENT_RESPONSIVE_ITEM_ALT_TEXT_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_ECONSENT_SELECT_DATE_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_ECONSENT_DATE: MvnCodeFE;
  DOCUMENT_ECONSENT_SELECT_DATE_FORMAT_LABEL: MvnCodeFE;
  DOCUMENT_ECONSENT_DATE_MISSING_LABEL: MvnCodeFE;
  DOCUMENT_ECONSENT_DATE_MISSING_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_ECONSENT_ADDRESS_FIELD_LABEL: MvnCodeFE;
  DOCUMENT_ECONSENT_ADDRESS_FIELD_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_ECONSENT_ADDRESS_MISSING_LABEL: MvnCodeFE;
  DOCUMENT_ECONSENT_ADDRESS_MISSING_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_ECONSENT_FOLLOW_WRITING_FIELD_TITLE: MvnCodeFE;
  DOCUMENT_ECONSENT_FOLLOW_WRITING_FIELD_LABEL: MvnCodeFE;
  DOCUMENT_ECONSENT_FOLLOW_WRITING_FIELD_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_ECONSENT_FOLLOW_WRITING_FIELD_MISSING: MvnCodeFE;
  DOCUMENT_ECONSENT_FOLLOW_WRITING_FIELD_FAILED_IDENTIFY_SIGNATURE: MvnCodeFE;
  DOCUMENT_ECONSENT_TEMPLATE: MvnCodeFE;
  DOCUMENT_ECONSENT_TEMPLATE_BUTTON: MvnCodeFE;
  DOCUMENT_ECONSENT_TEMPLATE_DIALOG_TITLE: MvnCodeFE;
  DOCUMENT_ECONSENT_TEMPLATE_DIALOG_DESCRIPTION: MvnCodeFE;
  DOCUMENT_ECONSENT_TEMPLATE_MISC_BUTTON: MvnCodeFE;
} = {
  DOCUMENT_CREATE_DIALOG_HEADER: {
    ko: "Add Document",
    en: "Add Document",
  },
  DOCUMENT_UPLOAD_FILE_DIALOG_HEADER: {
    ko: "Upload File",
    en: "Upload File",
  },
  DOCUMENT_TITLE_LABEL: {
    ko: "Title",
    en: "Title",
  },
  DOCUMENT_VERSION_LABEL: {
    ko: "Version",
    en: "Version",
  },
  DOCUMENT_AVAILABLE_STORAGE: {
    ko: "* 저장공간 {size}{unit} 사용가능",
    en: "* Storage {size}{unit} Available",
  },
  DOCUMENT_AVAILABLE_STORAGE_ARCHIVER: {
    ko: "{totalSize}{totalUnit} 중 {usageSize}{usageUnit} 사용",
    en: "{usageSize}{usageUnit} of {totalSize}{totalUnit} used",
  },
  DOCUMENT_FILE_LABEL: {
    ko: "File",
    en: "File",
  },
  DOCUMENT_FILE_PLACEHOLDER: {
    ko: "업로드할 파일을 선택해 주세요.",
    en: "Please choose a file to upload.",
  },
  DOCUMENT_FILE_DESCRIPTION: {
    ko: "최대 {num}MB의 {type}파일을 업로드할 수 있습니다.",
    en: "You can upload a {type}file up to {num}MB.",
  },
  DOCUMENT_FILE_TYPE_PLACEHOLDER: {
    ko: "업로드할 {type} 파일을 선택해 주세요.",
    en: "Please choose a {type} file to upload.",
  },
  DOCUMENT_FILE_SUCC: {
    ko: "파일 업로드에 성공했습니다.",
    en: "You have uploaded a file successfully.",
  },
  DOCUMENT_FILE_FAIL: {
    ko: "파일 업로드에 실패했습니다. ({message})",
    en: "Failed to upload a file. ({message})",
  },
  DOCUMENT_FILE_NOT_ALLOWED: {
    ko: "{type} 확장자의 파일만 업로드 할 수 있습니다.",
    en: "Only {type} files are allowed.",
  },
  DOCUMENT_CREATE_SUCC: {
    ko: "문서 생성에 성공했습니다.",
    en: "You have added a document successfully.",
  },
  DOCUMENT_CREATE_FAIL: {
    ko: "문서 생성에 실패했습니다. ({message})",
    en: "Failed to add a document. ({message})",
  },
  DOCUMENT_DOWNLOAD_SUCC: {
    ko: "문서 다운로드가 시작되었습니다.",
    en: "You have started to download a document successfully.",
  },
  DOCUMENT_DOWNLOAD_FAIL: {
    ko: "문서 다운로드에 실패했습니다. ({message})",
    en: "Failed to download a document. ({message})",
  },
  DOCUMENT_ESIGN_HEADER: {
    ko: "E-Sign 요청하기",
    en: "Request E-Sign",
  },
  DOCUMENT_ESIGN_CREATE_HEADER: {
    ko: "E-Sign 파일 생성하기",
    en: "Create E-Sign File",
  },
  DOCUMENT_ESIGN_CREATE_DESCRIPTION: {
    ko: "생성된 E-Sign Document에 대해서 API 방식으로 서명 요청을 진행할 수 있습니다.",
    en: "E-Sign request can be sent via API call.",
  },
  DOCUMENT_ESIGN_SIGNATURE_HEADER: {
    ko: "E-Sign 진행하기",
    en: "Proceed to E-Sign",
  },
  DOCUMENT_ESIGN_SIGNATURE_HEADER_SIGNED: {
    ko: "E-Sign 서명완료",
    en: "Completed to E-Sign",
  },
  DOCUMENT_ESIGN_SIGNATURE_HEADER_CANCELED: {
    ko: "E-Sign 취소됨",
    en: "Canceled to E-Sign",
  },
  DOCUMENT_ESIGN_SIGNATURE_HEADER_DECLINED: {
    ko: "E-Sign 거절됨",
    en: "Declined to E-Sign",
  },
  DOCUMENT_REQUEST_ESIGN_BUTTON: {
    ko: "E-Sign 요청하기",
    en: "Request E-Sign",
  },
  DOCUMENT_REQUEST_ESIGN_BUTTON_WITH_COUNT: {
    ko: "E-Sign 요청하기 (잔여 {count}건)",
    en: "Request E-Sign ({count} Remains)",
  },
  DOCUMENT_REQUEST_ESIGN_BUTTON_WITH_COUNT_UNLIMITED: {
    ko: "E-Sign 요청하기 (무제한)",
    en: "Request E-Sign (Unlimited)",
  },
  DOCUMENT_REQUEST_ESIGN_DESCRIPTION: {
    ko: "서명 요청 1건이 차감됩니다.",
    en: "1 E-Sign request will be used.",
  },
  DOCUMENT_CREATE_ESIGN_BUTTON: {
    ko: "E-Sign 파일 생성하기",
    en: "Create E-Sign File",
  },
  DOCUMENT_CANCEL_ESIGN_BUTTON: {
    ko: "E-Sign 취소하기",
    en: "Cancel E-Sign",
  },
  DOCUMENT_SIGN_ESIGN_BUTTON: {
    ko: "E-Sign 진행하기",
    en: "Proceed to E-Sign",
  },
  DOCUMENT_SIGN_ESIGN_ENTER_BUTTON: {
    ko: "서명 입력하기",
    en: "Enter Signature",
  },
  DOCUMENT_SIGN_ESIGN_ENTER_NOTI: {
    ko: "서명 영역을 클릭하여 서명을 입력해 주세요.",
    en: "Please enter your signature by clicking on an e-sign area.",
  },
  DOCUMENT_SIGN_ESIGN_REASON_LABEL: {
    ko: "서명 사유",
    en: "Signing Reason",
  },
  DOCUMENT_SIGN_ESIGN_REASON_PLACEHOLDER: {
    ko: "서명 사유를 입력해 주세요.",
    en: "Please enter the signing reason",
  },
  DOCUMENT_SIGN_ESIGN_REASON_APPROVER: {
    ko: "문서를 승인합니다.",
    en: "I approve this document",
  },
  DOCUMENT_SIGN_ESIGN_REASON_REVIEWER: {
    ko: "문서를 검토하였습니다.",
    en: "I have reviewed this document",
  },
  DOCUMENT_SIGN_ESIGN_REASON_OWNER: {
    ko: "이 문서의 소유자입니다.",
    en: "I am the owner of this document",
  },
  DOCUMENT_SIGN_ESIGN_REASON_OTHER: {
    ko: "기타 (직접 입력)",
    en: "Other (Direct Input)",
  },
  DOCUMENT_SIGN_ESIGN_EMAIL_VERIFICATION_LABEL: {
    ko: "이메일 인증코드",
    en: "Email Verification Code",
  },
  DOCUMENT_SIGN_ESIGN_EMAIL_VERIFICATION_PLACEHOLDER: {
    ko: "Email({email})로 전송된 인증코드를 입력해 주세요.",
    en: "Please enter the verification code sent via email({email}).",
  },
  DOCUMENT_SIGN_DECLINE_BUTTON: {
    ko: "E-Sign 거절하기",
    en: "Decline to E-Sign",
  },
  DOCUMENT_SIGN_CANCEL_BUTTON: {
    ko: "E-Sign 요청 취소하기",
    en: "Cancel to E-Sign Request",
  },
  DOCUMENT_SIGN_DOWNLOAD_BUTTON: {
    ko: "서명완료 문서 다운로드",
    en: "Download E-Signed Document",
  },
  DOCUMENT_SIGN_DOWNLOAD_BUTTON_GENERATING: {
    ko: "서명완료 문서 다운로드 (생성중 ..)",
    en: "Download E-Signed Doc. (Generating ..)",
  },
  DOCUMENT_SIGN_DOWNLOAD_REPORT_BUTTON: {
    ko: "감사 추적 보고서 다운로드",
    en: "Download Audit Trail Report",
  },
  DOCUMENT_SIGN_SIGNATURE_ENTER: {
    ko: "서명 입력",
    en: "Draw signature",
  },
  DOCUMENT_SIGN_STAMP_ENTER: {
    ko: "도장 입력",
    en: "Upload stamp image",
  },
  DOCUMENT_SIGN_STAMP_DESCRIPTION: {
    ko: "실제 도장을 찍은 사진을 업로드해 주세요.",
    en: "Please upload an image of actual stamp.",
  },
  DOCUMENT_ADD_SIGNER_BUTTON: {
    ko: "서명자 추가",
    en: "Add Signee",
  },
  DOCUMENT_NTH_SIGNER_LABEL: {
    ko: "서명자",
    en: "Signee",
  },
  DOCUMENT_NTH_INVESTIGATOR_LABEL: {
    ko: "연구자",
    en: "Principal Investigator",
  },
  DOCUMENT_SIGN_REQ_FIELDS_FULFILL: {
    ko: "필수 항목을 모두 입력해 주세요.",
    en: "Please fulfill all required fields.",
  },
  DOCUMENT_SIGN_SIGNATURE_MISSING: {
    ko: "서명을 입력해 주세요.",
    en: "Please enter your signature.",
  },
  DOCUMENT_SIGN_AGREE_AND_PROCEED: {
    ko: "동의 및 서명 완료",
    en: "Agree and Complete Sign",
  },
  DOCUMENT_SIGN_DIALOG_HEADER: {
    ko: "전자서명 이용약관",
    en: "E-Sign Agreement",
  },
  DOCUMENT_SIGN_DECLINE_DIALOG_HEADER: {
    ko: "전자서명 거절하기",
    en: "Decline to E-Sign",
  },
  DOCUMENT_SIGN_CANCEL_DIALOG_HEADER: {
    ko: "전자서명 요청 취소하기",
    en: "Cancel to E-Sign Request",
  },
  DOCUMENT_SIGN_DIALOG_AGREEMENT_EFFECT: {
    ko: "전자서명을 진행하고, 전자문서의 법적 효력에 대해 동의합니다.",
    en: "Proceed with the e-sign and consent to the legal effect of the e-document.",
  },
  DOCUMENT_SIGN_DIALOG_AGREEMENT_ORIGNAL: {
    ko: "전자서명이 완료된 후 전송되는 전자문서를 원본으로 인정합니다.",
    en: "I admit the e-document that will be sent after the e-sign completed as the original.",
  },
  DOCUMENT_SIGN_DIALOG_AGREEMENT_ETC_PREFIX: {
    ko: "기타 자세한 내용은 ",
    en: "I consent to other details by ",
  },
  DOCUMENT_SIGN_DIALOG_AGREEMENT_ETC_LINK: {
    ko: "전자서명 이용약관",
    en: "the terms and conditions of e-sign",
  },
  DOCUMENT_SIGN_DIALOG_AGREEMENT_ETC_SUFFIX: {
    ko: "에 따라 동의합니다.",
    en: ".",
  },
  DOCUMENT_SIGN_DIALOG_DECLINE_CONFIRM: {
    ko: "문서 서명 요청을 거절하시겠습니까?",
    en: "Are you sure to decline the document signing request?",
  },
  DOCUMENT_SIGN_DIALOG_DECLINE_WARNING: {
    ko: "전자서명 거절은 취소할 수 없습니다.",
    en: "The decline to request of e-sign cannot be reverted.",
  },
  DOCUMENT_SIGN_DIALOG_CANCEL_CONFIRM: {
    ko: "문서 서명 요청을 취소하시겠습니까?",
    en: "Are you sure to cancel the document signing request?",
  },
  DOCUMENT_SIGN_DIALOG_CANCEL_WARNING: {
    ko: "전자서명 요청 취소는 되돌릴 수 없습니다.",
    en: "The cancel to request of e-sign cannot be reverted.",
  },
  DOCUMENT_SIGN_CANCEL: {
    ko: "취소",
    en: "Cancel",
  },
  DOCUMENT_SIGN_REQUEST_SUCC: {
    ko: "문서 서명 요청에 성공했습니다.",
    en: "Document signing request has been created successfully.",
  },
  DOCUMENT_SIGN_REQUEST_FAIL: {
    ko: "문서 서명 요청에 실패했습니다. ({message})",
    en: "The document sign request failed. ({message})",
  },
  DOCUMENT_SIGN_CREATE_SUCC: {
    ko: "E-Sign 파일 생성에 성공했습니다.",
    en: "You have created an e-sign file successfully.",
  },
  DOCUMENT_SIGN_CREATE_FAIL: {
    ko: "E-Sign 파일 생성에 실패했습니다. ({message})",
    en: "Filaed to create an e-sign file. ({message})",
  },
  DOCUMENT_SIGN_ESIGN_SUCC: {
    ko: "문서 서명에 성공했습니다.",
    en: "Document has been signed successfully.",
  },
  DOCUMENT_SIGN_ESIGN_FAIL: {
    ko: "문서 서명에 실패했습니다. ({message})",
    en: "The document signinng request failed. ({message})",
  },
  DOCUMENT_SIGN_DECLINE_SUCC: {
    ko: "문서 서명 요청을 거절하였습니다.",
    en: "The document signing request was declined.",
  },
  DOCUMENT_SIGN_DECLINE_FAIL: {
    ko: "문서 서명 거절에 실패했습니다. ({message})",
    en: "Decline to document signing request failed. ({message})",
  },
  DOCUMENT_SIGN_CANCEL_SUCC: {
    ko: "문서 서명 요청을 취소하였습니다.",
    en: "The document signing request was canceled.",
  },
  DOCUMENT_SIGN_CANCEL_FAIL: {
    ko: "문서 서명 요청 취소에 실패했습니다. ({message})",
    en: "Cancel to document signing request failed. ({message})",
  },
  DOCUMENT_SIGN_NAME: {
    ko: "이름",
    en: "Name",
  },
  DOCUMENT_SIGN_NAME_PLACEHOLDER: {
    ko: "(이름)",
    en: "(Name)",
  },
  DOCUMENT_SIGN_EMAIL: {
    ko: "이메일",
    en: "Email",
  },
  DOCUMENT_SIGN_EMAIL_PLACEHOLDER: {
    ko: "(이메일)",
    en: "(Email)",
  },
  DOCUMENT_SIGN_COMPANY: {
    ko: "소속(회사)",
    en: "Company",
  },
  DOCUMENT_SIGN_COMPANY_PLACEHOLDER: {
    ko: "(소속(회사))",
    en: "(Company)",
  },
  DOCUMENT_SIGN_TITLE: {
    ko: "직위/직책",
    en: "Title",
  },
  DOCUMENT_SIGN_TITLE_PLACEHOLDER: {
    ko: "(직위/직책)",
    en: "(Title)",
  },
  DOCUMENT_SIGN_SIGNATURE: {
    ko: "서명",
    en: "Signature",
  },
  DOCUMENT_SIGN_SIGNATURE_PLACEHOLDER: {
    ko: "(서명)",
    en: "(Signature)",
  },
  DOCUMENT_SIGN_PHONE: {
    ko: "전화번호",
    en: "Phone",
  },
  DOCUMENT_SIGN_PHONE_PLACEHOLDER: {
    ko: "(전화번호)",
    en: "(Phone)",
  },
  DOCUMENT_SIGN_PHONE_DESCRIPTION: {
    ko: "하이픈(-)을 제외하고 숫자만 입력해 주세요.",
    en: "Please enter only numbers except hyphens(-).",
  },
  DOCUMENT_SIGN_DATE: {
    ko: "서명일",
    en: "Date",
  },
  DOCUMENT_SIGN_LABEL: {
    ko: "라벨",
    en: "Label",
  },
  DOCUMENT_SIGN_OPT_GXP_COMPLY: {
    ko: "GxP(21 CFR Part 11) 규정 준수",
    en: "Comply with GxP(21 CFR Part 11)",
  },
  DOCUMENT_SIGN_OPT_ADVANCED_MODE: {
    ko: "Advanced Mode",
    en: "Advanced Mode",
  },
  DOCUMENT_SIGN_OPT_REQUIRE_EMAIL_VERIFICATION: {
    ko: "문서 열람시 이메일 인증 필요",
    en: "Require email address verification to open",
  },
  DOCUMENT_SIGN_OPT_REQUIRE_PERSONAL_IDENTIFICATION: {
    ko: "문서 열람시 본인 인증 필요",
    en: "Require personal identification to open",
  },
  DOCUMENT_SIGN_OPT_ASSIGN_PROGRAMMATICALLY: {
    ko: "API 기반 서명자 지정",
    en: "Assign Signee programmatically",
  },
  DOCUMENT_SIGN_OPT_ESIGN_AUTOMATICALLY: {
    ko: "자동(시스템) E-Sign",
    en: "E-Sign automatically",
  },
  DOCUMENT_SIGN_OPT_SILENT_MODE: {
    ko: "Silent Mode (알림 메일 전송 생략)",
    en: "Silent mode (Notifications will not be sent)",
  },
  DOCUMENT_SIGN_OPT_RESPONSIVE_ITEM_INDEX: {
    ko: "응답 항목",
    en: "Responsive Item",
  },
  DOCUMENT_SIGN_OPT_ADD_RESPONSIVE_ITEM_GROUP: {
    ko: "응답 항목 그룹 추가",
    en: "Add Responsive Item Group",
  },
  DOCUMENT_SIGN_OPT_ADD_RESPONSIVE_ITEM: {
    ko: "응답 항목 추가",
    en: "Add Responsive Item",
  },
  DOCUMENT_SIGN_OPT_ADD_LABEL: {
    ko: "라벨 추가",
    en: "Add Label",
  },
  DOCUMENT_SIGN_OPT_RESPONSIVE_ITEM_MISSING_LABEL: {
    ko: "미입력시 안내 문구",
    en: "Alert Message for Missing Answer",
  },
  DOCUMENT_SIGN_OPT_RESPONSIVE_ITEM_MISSING_PLCAEHOLDER: {
    ko: "미입력시 표시할 문구를 입력해 주세요.",
    en: "Please enter a message for missing answer.",
  },
  DOCUMENT_SIGN_OPT_RESPONSIVE_ITEM_INVALID_LABEL: {
    ko: "오입력시 안내 문구",
    en: "Alert Message for Incorrect Answer",
  },
  DOCUMENT_SIGN_OPT_RESPONSIVE_ITEM_INVALID_PLCAEHOLDER: {
    ko: "오입력시 표시할 문구를 입력해 주세요.",
    en: "Please enter a message for incorrect answer.",
  },

  DOCUMENT_SIGN_OPT_STAMP_API_URL_LABEL: {
    ko: "API URL",
    en: "API URL",
  },
  DOCUMENT_SIGN_OPT_STAMP_API_URL_PLACEHOLDER: {
    ko: "URL (ex. https://api.com/stamp)",
    en: "URL (ex. https://api.com/stamp)",
  },
  DOCUMENT_SIGN_OPT_STAMP_API_TIMING_LABEL: {
    ko: "호출 시점",
    en: "Timing",
  },
  DOCUMENT_SIGN_OPT_STAMP_API_TIMING_PLACEHOLDER: {
    ko: "API 호출 시점을 선택해 주세요.",
    en: "Please select the API call timing.",
  },
  DOCUMENT_SIGN_OPT_ADD_STAMP_API: {
    ko: "Stamp API 연동 추가",
    en: "Add Stamp API Integration",
  },
  DOCUMENT_SIGN_HISTORY_REASON: {
    ko: "사유: ",
    en: "Reason: ",
  },
  DOCUMENT_SIGN_HISTORY_SIGN_START_PREFIX: {
    ko: "",
    en: "",
  },
  DOCUMENT_SIGN_HISTORY_SIGN_START_SUFFIX: {
    ko: "님이 문서 서명 요청을 시작하였습니다.",
    en: " has started a document signing request.",
  },
  DOCUMENT_SIGN_HISTORY_DELIVERED_PREFIX: {
    ko: "",
    en: "Signing document has been delivered to ",
  },
  DOCUMENT_SIGN_HISTORY_DELIVERED_SUFFIX: {
    ko: "님에게 서명할 문서를 전송하였습니다.",
    en: "",
  },
  DOCUMENT_SIGN_HISTORY_REMIND_PREFIX: {
    ko: "",
    en: "Signing document has been resent to ",
  },
  DOCUMENT_SIGN_HISTORY_REMIND_SUFFIX: {
    ko: "님에게 서명할 문서를 재전송하였습니다.",
    en: "",
  },
  DOCUMENT_SIGN_HISTORY_OPENED_PREFIX: {
    ko: "",
    en: "",
  },
  DOCUMENT_SIGN_HISTORY_OPENED_SUFFIX: {
    ko: "님이 서명할 문서를 열람하였습니다.",
    en: " has opened the document to sign.",
  },
  DOCUMENT_SIGN_HISTORY_SIGNED_PREFIX: {
    ko: "",
    en: "",
  },
  DOCUMENT_SIGN_HISTORY_SIGNED_SUFFIX: {
    ko: "님이 문서에 서명을 완료하였습니다.",
    en: " has completed signing the document.",
  },
  DOCUMENT_SIGN_HISTORY_DECLINED_PREFIX: {
    ko: "",
    en: "",
  },
  DOCUMENT_SIGN_HISTORY_DECLINED_SUFFIX: {
    ko: "님이 문서에 서명을 거절하였습니다.",
    en: " has declined signing the document.",
  },
  DOCUMENT_SIGN_HISTORY_CANCELED_PREFIX: {
    ko: "",
    en: "",
  },
  DOCUMENT_SIGN_HISTORY_CANCELED_SUFFIX: {
    ko: "님이 문서 서명 요청을 취소하였습니다.",
    en: " has canceled signing request to the document.",
  },
  DOCUMENT_SIGN_HISTORY_CANCEL_REASON_PREFIX: {
    ko: "(사유: ",
    en: "(Reason: ",
  },
  DOCUMENT_SIGN_HISTORY_CANCEL_REASON_SUFFIX: {
    ko: ")",
    en: ")",
  },
  DOCUMENT_SIGN_HISTORY_SIGN_EXPIRED_PREFIX: {
    ko: "문서 서명 요청이 만료되었습니다.",
    en: "The e-sign request to document has expired.",
  },
  DOCUMENT_SIGN_HISTORY_SIGN_EXPIRED_SUFFIX: {
    ko: "",
    en: "",
  },
  DOCUMENT_SIGN_HISTORY_SIGN_END_PREFIX: {
    ko: "문서의 서명이 완료되었습니다.",
    en: "The document signing has completed.",
  },
  DOCUMENT_SIGN_HISTORY_SIGN_END_SUFFIX: {
    ko: "",
    en: "",
  },
  DOCUMENT_SIGN_HISTORY_SIGN_DECLINE_PREFIX: {
    ko: "문서 서명 요청 거절이 완료되었습니다.",
    en: "The decline request to document signing has completed.",
  },
  DOCUMENT_SIGN_HISTORY_SIGN_DECLINE_SUFFIX: {
    ko: "",
    en: "",
  },
  DOCUMENT_SIGN_HISTORY_SIGN_CANCEL_PREFIX: {
    ko: "문서 서명 요청 취소가 완료되었습니다.",
    en: "The cancel request to document signing has completed.",
  },
  DOCUMENT_SIGN_HISTORY_SIGN_CANCEL_SUFFIX: {
    ko: "",
    en: "",
  },
  DOCUMENT_SIGN_CERTIFICATE_DESC_DIGEST: {
    ko: "각 Action에 대한 정보를 포함하는 값을 의미합니다.",
    en: "Represents the value of each Action’s information",
  },
  DOCUMENT_SIGN_CERTIFICATE_DESC_DIGEST_HASH: {
    ko: "Digest 값을 SHA512 알고리즘으로 단방향 암호화한 값을 의미합니다.",
    en: "Represents the Digest value of one way encryption in SHA512 Algorithm",
  },
  DOCUMENT_SIGN_CERTIFICATE_DESC_HASH_CHAIN: {
    ko: "각 Digest Hash 값과 이전의 Hash Chain 값을 SHA1 알고리즘으로 단방향 암호화한 값을 의미합니다. 모든 Audit Trail은 서명 요청 시점부터 Chaining 되어 데이터의 위/변조를 원천 봉쇄합니다.",
    en: "Represents each Digest Hash value and previous Hash Chain value of one way encryption in SHA1 algorithm. Every audit trail are chained to prevent and block any bias or manipulation starting from e-sign request.",
  },
  DOCUMENT_SIGN_DIALOG_CANCEL_REASON: {
    ko: "취소사유",
    en: "Cancellation reason",
  },
  DOCUMENT_SIGN_DIALOG_CHANGE_DOC: {
    ko: "문서를 변경합니다.",
    en: "Change the document.",
  },
  DOCUMENT_SIGN_DIALOG_CHANGE_SIGNEE: {
    ko: "서명자 정보를 변경합니다.",
    en: "Change made to the signee's information.",
  },
  DOCUMENT_SIGN_DIALOG_DELETE_SIGNEE: {
    ko: "연구 대상자를 삭제했습니다.",
    en: "Delete the subject.",
  },
  DOCUMENT_SIGN_DIALOG_OTHER: {
    ko: "기타 (직접 입력)",
    en: "Other (Direct Input)",
  },
  DOCUMENT_SIGN_ECONSENT_BUTTON: {
    ko: "eConsent 진행하기",
    en: "Proceed to eConsent",
  },
  DOCUMENT_SIGN_FAILED_IDENTIFY_SIGNATURE: {
    ko: "서명을 식별할 수 없습니다. 더 크게 입력해 주세요.",
    en: "Failed to identify signature. Please enter signature larger.",
  },

  DOCUMENT_ECONSENT_SIGNATURE_HEADER: {
    ko: "eConsent 진행하기",
    en: "Proceed to eConsent",
  },
  DOCUMENT_ECONSENT_SIGNATURE_HEADER_SIGNED: {
    ko: "eConsent 서명완료",
    en: "Completed to eConsent",
  },
  DOCUMENT_ECONSENT_SIGNATURE_HEADER_CANCELED: {
    ko: "eConsent 취소됨",
    en: "Canceled to eConsent",
  },
  DOCUMENT_ECONSENT_SIGNATURE_HEADER_DECLINED: {
    ko: "eConsent 거절됨",
    en: "Declined to eConsent",
  },
  DOCUMENT_SIGN_ECONSENT_REASON_APPROVER_SIGNER: {
    ko: "본 연구 참여에 동의합니다.",
    en: "I consent to the participation of this study.",
  },
  DOCUMENT_SIGN_ECONSENT_REASON_APPROVER_INVESTIGATOR: {
    ko: "연구 대상자가 충분한 이해 후 자발적으로 동의하였음을 확인했습니다.",
    en: "I confirm the subject has read and understood the study and has voluntarily consented to participation.",
  },
  DOCUMENT_ECONSENT_DECLINE_BUTTON: {
    ko: "eConsent 거절하기",
    en: "Decline to eConsent",
  },
  DOCUMENT_ECONSENT_CANCEL_BUTTON: {
    ko: "eConsent 요청 취소하기",
    en: "Cancel to eConsent Request",
  },
  DOCUMENT_ECONSENT_DOWNLOAD_BUTTON: {
    ko: "전자 동의 완료 문서 다운로드",
    en: "Download E-Signed Document",
  },
  DOCUMENT_ECONSENT_DOWNLOAD_BUTTON_GENERATING: {
    ko: "전자 동의 완료 문서 다운로드 (생성중 ..)",
    en: "Download E-Signed Doc. (Generating ..)",
  },
  DOCUMENT_ECONSENT_DIALOG_HEADER: {
    ko: "전자동의 이용약관",
    en: "eConsent Agreement",
  },
  DOCUMENT_ECONSENT_DECLINE_DIALOG_HEADER: {
    ko: "전자동의 거절하기",
    en: "Decline to eConsent",
  },
  DOCUMENT_ECONSENT_CANCEL_DIALOG_HEADER: {
    ko: "전자동의 요청 취소하기",
    en: "Cancel to eConsent Request",
  },
  DOCUMENT_ECONSENT_DIALOG_AGREEMENT_EFFECT: {
    ko: "전자동의를 진행하고, 전자문서의 법적 효력에 대해 동의합니다.",
    en: "Proceed with the eConsent and consent to the legal effect of the e-document.",
  },
  DOCUMENT_ECONSENT_DIALOG_AGREEMENT_ORIGNAL: {
    ko: "전자동의가 완료된 후 전송되는 전자문서를 원본으로 인정합니다.",
    en: "I admit the e-document that will be sent after the eConsent completed as the original.",
  },
  DOCUMENT_ECONSENT_DIALOG_AGREEMENT_ETC_LINK: {
    ko: "전자동의 이용약관",
    en: "the terms and conditions of eConsent",
  },
  DOCUMENT_ECONSENT_DIALOG_DECLINE_WARNING: {
    ko: "전자동의 거절은 취소할 수 없습니다.",
    en: "The decline to request of eConsent cannot be reverted.",
  },
  DOCUMENT_ECONSENT_DIALOG_CANCEL_WARNING: {
    ko: "전자동의 요청 취소는 되돌릴 수 없습니다.",
    en: "The cancel to request of eConsent cannot be reverted.",
  },
  DOCUMENT_ECONSENT_LABEL: {
    ko: "옵션",
    en: "Option",
  },
  DOCUMENT_ECONSENT_REQ: {
    ko: "정답",
    en: "Correct",
  },
  DOCUMENT_ECONSENT_OPT_RESPONSIVE_ITEM_INDEX: {
    ko: "질문 항목",
    en: "Question Item",
  },
  DOCUMENT_ECONSENT_OPT_ADD_RESPONSIVE_ITEM_GROUP: {
    ko: "질문 추가",
    en: "Add Question",
  },
  DOCUMENT_ECONSENT_OPT_ADD_OPTION: {
    ko: "옵션 추가",
    en: "Add Option",
  },
  DOCUMENT_ECONSENT_CREATE_ICF_DIALOG_HEADER: {
    ko: "Create ICF",
    en: "Create ICF",
  },
  DOCUMENT_ECONSENT_ADD_SUBJECTS_DIALOG_HEADER: {
    ko: "Add Subjects",
    en: "Add Subjects",
  },
  DOCUMENT_ECONSENT_ADD_SUBJECT_DIALOG_HEADER: {
    ko: "Add Subject",
    en: "Add Subject",
  },
  DOCUMENT_EXCEL_DOWNLOAD_DESCRIPTION: {
    ko: "엑셀 템플릿을 다운로드 받아서 작성해 주세요.",
    en: "Please download and fill out the Excel template.",
  },
  DOCUMENT_ECONSENT_ADD_SUBJECT_LIMIT: {
    ko: "최대 1,000명까지 한 번에 등록하실 수 있습니다.",
    en: "You can add up to 1,000 subjects at once.",
  },
  DOCUMENT_ECONSENT_SUBJECTS_VALIDATE: {
    ko: "중복과 무효한 항목을 제외한 총 {count}명의 서명 대상자를 등록하시겠습니까?",
    en: "Are you sure to add {count} subjects excluding duplicates and invalids?",
  },
  DOCUMENT_ECONSENT_ADD_SUBJECTS_SUCC: {
    ko: "연구 대상자 등록에 성공했습니다.",
    en: "You have added subjects successfully.",
  },
  DOCUMENT_ECONSENT_ADD_SUBJECTS_FAIL: {
    ko: "연구 대상자 등록에 실패했습니다.",
    en: "Failed to add subjects.",
  },
  DOCUMENT_ECONSENT_ADD_SUBJECT_FAIL: {
    ko: "연구 대상자 등록에 실패했습니다. ({message})",
    en: "Failed to add a subject. ({message})",
  },
  DOCUMENT_ECONSENT_CREATE_ICF_SECTION_HEADER: {
    ko: "ICF 생성하기",
    en: "Create ICF",
  },
  DOCUMENT_ECONSENT_CREATE_ICF_SECTION_BUTTON: {
    ko: "ICF 생성하기",
    en: "Create ICF",
  },
  DOCUMENT_ECONSENT_CREATE_ICF_SUCC: {
    ko: "문서 생성에 성공했습니다.",
    en: "You have added a document successfully.",
  },
  DOCUMENT_ECONSENT_CREATE_ICF_FAIL: {
    ko: "문서 생성에 실패했습니다. ({message})",
    en: "Failed to add a document. ({message})",
  },
  DOCUMENT_ECONSENT_SEND_ECONSENT_BUTTON: {
    ko: "Send eConsent",
    en: "Send eConsent",
  },
  DOCUMENT_ECONSENT_SEND_ECONSENT_DIALOG_HEADER: {
    ko: "Send eConsent",
    en: "Send eConsent",
  },
  DOCUMENT_ECONSENT_SEND_ECONSENT_DIALOG_PLACEHOLDER: {
    ko: "ICF를 선택해 주세요.",
    en: "Please choose an ICF to send eConsent.",
  },
  DOCUMENT_ECONSENT_SEND_ECONSENT_DIALOG_DESCRIPTION: {
    ko: "{count}명의 대상자에게 전자 동의서를 전송하시겠습니까?",
    en: "Are you sure to send eConsent to {count} subjects?",
  },
  DOCUMENT_ECONSENT_SEND_SUCC: {
    ko: "전자 동의서 전송에 성공했습니다.",
    en: "You have send eConsents successfully.",
  },
  DOCUMENT_ECONSENT_SEND_PARTIAL_SUCC_SUFFIX: {
    ko: "(일부는 전송에 실패했습니다.)",
    en: "(Some eConsents have failed to send.)",
  },
  DOCUMENT_ECONSENT_SEND_FAIL: {
    ko: "전자 동의서 전송에 실패했습니다. ({message})",
    en: "You have failed to send eConsents. ({message})",
  },
  // NOTE: 다른 부분의 Audit에서도 지속적으로 Message가 필요하면 Audit파일로 분리가 좋을 것 같음
  DOCUMENT_ECONSENT_AUDIT_VALUE_SIGNER_CREATED: {
    ko: "{count}명 연구대상자 등록",
    en: "{count} subject(s) added",
  },
  DOCUMENT_ECONSENT_AUDIT_VALUE_SIGNER_DELETED: {
    ko: "{count}명 연구대상자 삭제",
    en: "{count} subject(s) deleted",
  },
  DOCUMENT_ECONSENT_AUDIT_VALUE_SIGN_REQUESTED: {
    ko: "{count}명에게 전자 동의 전송",
    en: "eConsent sended to {count} subject(s)",
  },
  DOCUMENT_SEND_ECONSENT_OPTION_EMAIL: {
    ko: "이메일 전송",
    en: "Send via Email",
  },
  DOCUMENT_SEND_ECONSENT_OPTION_MOBILE: {
    ko: "문자 전송",
    en: "Send via SMS",
  },
  DOCUMENT_SEND_ECONSENT_ORGANIZATION_TITLE: {
    ko: "기관명",
    en: "Organization Name",
  },
  DOCUMENT_SEND_ECONSENT_ORGANIZATION_PLACEHOLDER: {
    ko: "전자동의를 요청하는 메일/문자에 표시됩니다.",
    en: "It is displayed in Email/SMS to request eConsent.",
  },
  DOCUMENT_SEND_ECONSENT_EMAIL_IMAGE_TITLE: {
    ko: "이메일 안내 이미지",
    en: "Guide Image in Email",
  },
  DOCUMENT_SEND_ECONSENT_EMAIL_IMAGE_PLACEHOLDER: {
    ko: "이메일에 삽입할 이미지 파일을 선택해 주세요.",
    en: "Please Choose an image file to insert in Email.",
  },
  DOCUMENT_SEND_ECONSENT_SMS_CONTENT_TITLE: {
    ko: "연구 대상자 문자 내용",
    en: "SMS to Subjects",
  },
  DOCUMENT_SEND_ECONSENT_SMS_CONTENT_COMMON: {
    ko: "{ICF 이름} 전자동의 정보를 확인해 주세요. 아래의 링크를 누르면 전자동의 문서로 이동합니다.\n\n▶ 전자동의 문서에 서명하기 {링크}",
    en: "Please confirm {ICF Title} eConsent information. Click the link below to move to the eConsent document.\n\n▶ Sign eConsent document {Link}",
  },
  DOCUMENT_SEND_ECONSENT_SMS_CONTENT_PLACEHOLDER: {
    ko: "공통 문구 아래에 들어갈 문자 내용을 작성해 주세요.",
    en: "Please enter a SMS content below the common phrase.",
  },
  DOCUMENT_SEND_ECONSENT_SMS_CONTENT_DESCRIPTION: {
    ko: "최대 {maxLength}자를 작성할 수 있습니다.",
    en: "You can enter up to {maxLength} characters.",
  },
  DOCUMENT_SEND_ECONSENT_TITLE_SUFFIX_KO: {
    ko: "(한)",
    en: "(KO)",
  },
  DOCUMENT_SEND_ECONSENT_TITLE_SUFFIX_EN: {
    ko: "(영)",
    en: "(EN)",
  },
  DOCUMENT_ECONSENT_REQUEST_DOWNLOAD_DOCUMENT_SUCC: {
    ko: "전자동의 완료 문서 생성이 요청되었습니다.",
    en: "eConsent document download has been requested.",
  },
  DOCUMENT_ECONSENT_REQUEST_DOWNLOAD_DOCUMENT_FAIL: {
    ko: "전자동의 완료 문서 생성 요청에 실패했습니다. ({message})",
    en: "Failed to reqeust eConsent document download. ({message})",
  },
  DOCUMENT_ECONSENT_DONOR: {
    ko: "기증자",
    en: "Donor",
  },
  DOCUMENT_ECONSENT_AGENT: {
    ko: "법정 대리인",
    en: "Agent",
  },
  DOCUMENT_ECONSENT_AGENT_ACTIVE: {
    ko: "법정 대리인 활성화",
    en: "Activate Agent",
  },
  DOCUMENT_ECONSENT_AGENT_SIGN_SIGNATURE: {
    ko: "법정 대리인 서명",
    en: "Agent Signature",
  },
  DOCUMENT_ECONSENT_TEXT_INPUT_FIELD_TITLE: {
    ko: "텍스트",
    en: "Text",
  },
  DOCUMENT_ECONSENT_TEXT_INPUT_FIELD_LABEL: {
    ko: "가이드 입력란",
    en: "Input Guide",
  },
  DOCUMENT_ECONSENT_TEXT_INPUT_FIELD_PLACEHOLDER: {
    ko: "작성 예시) 이름을 입력해 주세요.",
    en: "Example) Please enter your name.",
  },
  DOCUMENT_ECONSENT_TEXT_FREE_FIELD_LABEL: {
    ko: "자유 텍스트 입력",
    en: "Free Text",
  },
  DOCUMENT_ECONSENT_TEXT_DATE_FIELD_LABEL: {
    ko: "날짜 입력 ({format})",
    en: "Date Input ({format})",
  },
  DOCUMENT_ECONSENT_SINGLE_SELECT_FIELD_TITLE: {
    ko: "단일 선택",
    en: "Single Select",
  },
  DOCUMENT_ECONSENT_DROPDOWN_FIELD_TITLE: {
    ko: "드롭다운",
    en: "Dropdown",
  },
  DOCUMENT_ECONSENT_DROPDOWN_FILED_LABEL: {
    ko: "옵션 입력",
    en: "Input Options",
  },
  DOCUMENT_ECONSENT_DROPDOWN_FIELD_PLACEHOLDER: {
    ko: "옵션을 입력해 주세요. (;로 구분)",
    en: "Please enter the options. (Separated by ;)",
  },
  DOCUMENT_ECONSENT_DROPDOWN_MISSING_LABEL: {
    ko: "가이드 입력란",
    en: "Input Guide",
  },
  DOCUMENT_ECONSENT_DROPDOWN_MISSING_PLACEHOLDER: {
    ko: "작성 예시) 지역번호를 선택해 주세요.",
    en: "Example) Please select the area code.",
  },
  DOCUMENT_ECONSENT_DROPDOWN_OPTION_ITEM_MAX_COUNT: {
    ko: "옵션은 최대 {count}개까지 입력할 수 있습니다.",
    en: "You can enter up to {count} options.",
  },
  DOCUMENT_ECONSENT_ADDRESS_FIELD_TITLE: {
    ko: "주소",
    en: "Address",
  },
  DOCUMENT_ECONSENT_ADD_ITEM_GROUP: {
    ko: "입력 항목 추가",
    en: "Add Field",
  },
  DOCUMENT_ECONSENT_SELECT_TEXT_FIELD_OPTION_LABEL: {
    ko: "입력 유형 선택",
    en: "Select Input Type",
  },
  DOCUMENT_ECONSENT_SELECT_TEXT_FIELD_OPTION_PLACEHOLDER: {
    ko: "입력 유형을 선택해 주세요.",
    en: "Please select the input type.",
  },
  DOCUMENT_ECONSENT_CONSENT: {
    ko: "단일 선택",
    en: "Single Select",
  },
  DOCUMENT_ECONSENT_CONSENT_ITEM: {
    ko: "단일 선택 항목",
    en: "Single Select Item",
  },
  DOCUMENT_ECONSENT_ADD_CONSENT_ITEM: {
    ko: "동의 내용 항목 추가",
    en: "Add Consent Item",
  },
  DOCUMENT_ECONSENT_RESPONSIVE_ITEM_ALT_TEXT_PLACEHOLDER: {
    ko: "옵션을 선택하면 노출될 텍스트입니다.",
    en: "Text to be displayed when an option is selected.",
  },
  DOCUMENT_ECONSENT_SELECT_DATE_PLACEHOLDER: {
    ko: "날짜를 선택해 주세요.",
    en: "Please select a date.",
  },
  DOCUMENT_ECONSENT_DATE: {
    ko: "날짜",
    en: "Date",
  },
  DOCUMENT_ECONSENT_SELECT_DATE_FORMAT_LABEL: {
    ko: "날짜 형식",
    en: "Date Format",
  },
  DOCUMENT_ECONSENT_DATE_MISSING_LABEL: {
    ko: "가이드 입력란",
    en: "Input Guide",
  },
  DOCUMENT_ECONSENT_DATE_MISSING_PLACEHOLDER: {
    ko: "작성 예시) 생년월일을 입력해 주세요.",
    en: "Example) Please enter your birth date.",
  },
  DOCUMENT_ECONSENT_ADDRESS_FIELD_LABEL: {
    ko: "입력 유형",
    en: "Address Input",
  },
  DOCUMENT_ECONSENT_ADDRESS_FIELD_PLACEHOLDER: {
    ko: "도로명 주소",
    en: "Please enter the address.",
  },
  DOCUMENT_ECONSENT_ADDRESS_MISSING_LABEL: {
    ko: "가이드 입력란",
    en: "Input Guide",
  },
  DOCUMENT_ECONSENT_ADDRESS_MISSING_PLACEHOLDER: {
    ko: "작성 예시) 주소를 입력해 주세요.",
    en: "Example) Please enter the address.",
  },
  DOCUMENT_ECONSENT_FOLLOW_WRITING_FIELD_TITLE: {
    ko: "따라 쓰기",
    en: "Follow Writing",
  },
  DOCUMENT_ECONSENT_FOLLOW_WRITING_FIELD_LABEL: {
    ko: "따라 쓸 내용",
    en: "Follow Writing Content",
  },
  DOCUMENT_ECONSENT_FOLLOW_WRITING_FIELD_PLACEHOLDER: {
    ko: "따라 쓸 내용을 입력해 주세요.",
    en: "Please enter the content to follow.",
  },
  DOCUMENT_ECONSENT_FOLLOW_WRITING_FIELD_MISSING: {
    ko: "따라쓰기를 입력해 주세요.",
    en: "Please enter the content to follow.",
  },
  DOCUMENT_ECONSENT_FOLLOW_WRITING_FIELD_FAILED_IDENTIFY_SIGNATURE: {
    ko: "따라쓰기를 식별할 수 없습니다. 더 크게 입력해 주세요.",
    en: "Failed to identify follow writing. Please enter larger.",
  },
  DOCUMENT_ECONSENT_TEMPLATE: {
    ko: "서명 정보 템플릿",
    en: "Signature Template",
  },
  DOCUMENT_ECONSENT_TEMPLATE_BUTTON: {
    ko: "서명 정보 템플릿 추가",
    en: "Add Signature Template",
  },
  DOCUMENT_ECONSENT_TEMPLATE_DIALOG_TITLE: {
    ko: "서명 정보 템플릿 추가",
    en: "Add Signature Template",
  },
  DOCUMENT_ECONSENT_TEMPLATE_DIALOG_DESCRIPTION: {
    ko: "서명 정보 템플릿을 구성해 주세요.",
    en: "Please configure the signature template.",
  },
  DOCUMENT_ECONSENT_TEMPLATE_MISC_BUTTON: {
    ko: "Reset",
    en: "Reset",
  },
};

MvnCodeInitiate(DOCS_DOCUMENT_LANG);
