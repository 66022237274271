import { MvnCodeFE } from "../const";

export const BUILDER_COMMON_LANG: {
  BUILDER_COMMON_PAGE_MOVEMENT_DETECTION_ALERT_MESSAGE_BEFORE_SAVE: MvnCodeFE;
} = {
  BUILDER_COMMON_PAGE_MOVEMENT_DETECTION_ALERT_MESSAGE_BEFORE_SAVE: {
    ko: "저장되지 않은 변경사항이 있습니다. 이 페이지에서 나가시겠습니까?",
    en: "You have unsaved changes. Are you sure you want to leave this page?",
  },
};
