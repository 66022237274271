import { useBaseQuery } from "./common";
import { BuilderPopupFlax } from "@maven-rest/builder/Popup.flax";

export enum PopupQueryKey {
  Popup = "POPUP",
}

export const usePopupQuery = () => {
  return useBaseQuery({
    queryKey: [PopupQueryKey.Popup],
    fnOption: { fxApi: BuilderPopupFlax.getPopups({}) },
  });
};
