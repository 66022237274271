import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_TEMPLATE_PAYLOAD_REQUEST_LANG: {
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_ADD_SUCC: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_ADD_FAIL: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_ADD_CONFIRM_DIALOG_TITLE: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_ADD_CONFIRM_DIALOG_DESC: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_CANCEL_SUCC: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_CANCEL_FAIL: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_CANCEL_CONFIRM_DIALOG_DESC: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_APPROVE_SUCC: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_APPROVE_FAIL: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_APPROVE_CONFIRM_DIALOG_TITLE: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_APPROVE_CONFIRM_DIALOG_DESC: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_REJECT_SUCC: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_REJECT_FAIL: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_REJECT_CONFIRM_DIALOG_TITLE: MvnCodeFE;
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_REJECT_CONFIRM_DIALOG_DESC: MvnCodeFE;
} = {
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_ADD_SUCC: {
    ko: "Study Template CRF version 승인을 요청하였습니다.",
    en: "You have requested study template CRF version approval successfully.",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_ADD_FAIL: {
    ko: "Study Template CRF version 승인 요청에 실패했습니다. ({message})",
    en: "Failed to request study template CRF version approval. ({message})",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_ADD_CONFIRM_DIALOG_TITLE: {
    ko: "Ask for Approval",
    en: "Ask for Approval",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_ADD_CONFIRM_DIALOG_DESC: {
    ko: "승인 요청을 위해 이메일과 비밀번호를 입력하세요.",
    en: "Please enter your e-mail and password to ask for approval.",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_CANCEL_SUCC: {
    ko: "Study Template CRF version 승인 요청을 취소했습니다.",
    en: "You have cancelled study template CRF version approval request successfully.",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_CANCEL_FAIL: {
    ko: "Study Template CRF version 승인 요청을 취소에 실패했습니다. ({message})",
    en: "Failed to cancel study template CRF version approval request. ({message})",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_CANCEL_CONFIRM_DIALOG_DESC: {
    ko: "작업 중으로 돌아갑니다. 요청을 취소하시겠습니까?",
    en: "The status will go back to In Progress. Are you sure you want to cancel the request?",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_APPROVE_SUCC: {
    ko: "Study Template CRF version을 승인했습니다.",
    en: "You have approved study template CRF version approval request successfully.",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_APPROVE_FAIL: {
    ko: "Study Template CRF version 승인에 실패했습니다. ({message})",
    en: "Failed to approve study template CRF version approval request. ({message})",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_APPROVE_CONFIRM_DIALOG_TITLE: {
    ko: "Add to Library",
    en: "Add to Library",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_APPROVE_CONFIRM_DIALOG_DESC: {
    ko: "library에 추가 할 템플릿을 승인하기 위해 이메일과 비밀번호를 입력하세요.",
    en: "Please enter your e-mail and password to approve this template to add to your library.",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_REJECT_SUCC: {
    ko: "Study Template CRF version 승인을 거절했습니다.",
    en: "You have rejected study template CRF version approval request successfully.",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_REJECT_FAIL: {
    ko: "Study Template CRF version 승인 거절에 실패했습니다. ({message})",
    en: "Failed to reject study template CRF version approval request. ({message})",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_REJECT_CONFIRM_DIALOG_TITLE: {
    ko: "Reject to Add to Library",
    en: "Reject to Add to Library",
  },
  BUILDER_STUDY_BUILDER_TEMPLATE_PAYLOAD_REQUEST_REJECT_CONFIRM_DIALOG_DESC: {
    ko: "library에 추가 할 템플릿을 승인 거절하기 위해 이메일과 비밀번호를 입력하세요.",
    en: "Please enter your e-mail and password to reject this template to add to your library.",
  },
};
