import { useEffect, useState } from "react";
import { BUILDER_NOTICE_POPUP_KEY, NoticePopup } from "./NoticePopup";
import { AdminNoticeWithContent } from "@maven-rest/admin/Notice.dto";
import { AdminReleaseWithContent } from "@maven-rest/admin/Release.dto";
import { getCookie } from "@maven-rest/states";

interface NoticePopupManagerProps {
  notices?: Array<AdminNoticeWithContent>;
  release?: AdminReleaseWithContent;
  onAllNoticesClosed?: () => void;
}

export const NoticePopupManager = ({ notices, release, onAllNoticesClosed }: NoticePopupManagerProps) => {
  const [currentNoticeIndex, setCurrentNoticeIndex] = useState<number>(0);
  const [isNoticeOpen, setIsNoticeOpen] = useState(false);

  useEffect(() => {
    if (!notices?.length) return;

    if (getCookie(`${BUILDER_NOTICE_POPUP_KEY}:${notices[currentNoticeIndex]?.id}`)) {
      setCurrentNoticeIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;

        if (nextIndex < notices.length) {
          return nextIndex;
        } else {
          setIsNoticeOpen(false);
          onAllNoticesClosed && onAllNoticesClosed();

          return -1;
        }
      });
    } else {
      setIsNoticeOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNoticeIndex, notices]);

  useEffect(() => {
    if (!notices?.length) return;

    const index = notices.findIndex((notice) => !getCookie(`${BUILDER_NOTICE_POPUP_KEY}:${notice.id}`));

    if (index !== -1) {
      setCurrentNoticeIndex(index);
      setIsNoticeOpen(true);
    } else {
      setIsNoticeOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [release, notices]);

  const handleNoticeClose = () => {
    if (!notices?.length) return;

    if (currentNoticeIndex < notices.length - 1) {
      setCurrentNoticeIndex(currentNoticeIndex + 1);
    } else {
      setIsNoticeOpen(false);
    }

    if (currentNoticeIndex === notices.length - 1) {
      onAllNoticesClosed && onAllNoticesClosed();
    }
  };

  if (!isNoticeOpen || currentNoticeIndex === -1) {
    return null;
  }

  return (
    <>
      {!!notices?.length && (
        <NoticePopup
          key={notices[currentNoticeIndex].id}
          open={isNoticeOpen}
          notice={notices[currentNoticeIndex]}
          onClose={handleNoticeClose}
        />
      )}
    </>
  );
};
